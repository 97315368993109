<script>
    import SVGBoard from './board/svgboard.svelte';
    import OnlineModal from './onlinemodal.svelte';
    import GameOverModal from './gameovermodal.svelte';
    import DOMStone from './board/domstone.svelte';
    import { notify } from './ui/notify.svelte';
    import Board from './board/board.ts';
    import OnlinePlayer from './onlineplayer.ts';
    import { onMount } from 'svelte';

    export let started;

	let board = new Board(9);
	let onlinePlayer;

    let clientWidth;
    let clientHeight;
    let boardWidth;

    $: boardWidth = Math.min(clientWidth, clientHeight);

    $: started = board && board.last || onlinePlayer && onlinePlayer.entered;

    export function start(options) {
        switch (options.mode) {

            case 'local':
                console.log('Local game:', options);
                board = new Board(options.size);
                onlinePlayer = null;
                break;

            case 'join':
            case 'host':
                console.log('Online game:', options);
                onlinePlayer = new OnlinePlayer({
                    onOpen: () => {
                        onlinePlayer = onlinePlayer;
                    },
                    onEnter: data => {
                        onlinePlayer = onlinePlayer;
                        if (options.mode == 'host') {
                            board = new Board(options.size);
                        } else {
                            board = new Board(data.size);
                        }
                    },
                    onPlay: data => onOnlinePlay(data),
                    onPass: data => onOnlinePlay(data),
                    onLeave: () => {
                        onlinePlayer.destroy();
                        onlinePlayer = null;
                        notify('Player left');
                    },
                    onError: error => {
                        onlinePlayer.destroy();
                        onlinePlayer = null;
                        onError(error);
                    },
                    ...options
                });
                if (options.mode == 'host') {
                    onlinePlayer.listen();
                } else {
                    onlinePlayer.connect();
                }
                break;
        }
    }

    function getColor(c) {
        switch (c) {
            case Board.Black:
                return 'black';
            case Board.White:
                return 'white';
        }
        return '';
    }

    function onLocalPlay(event) {
        if (onlinePlayer && onlinePlayer.color == getColor(board.color)) {
            //console.warn('Local player played while it is not its turn');
            return;
        }

        const i = event.detail.row;
        const j = event.detail.col;

        if (board.play(i, j)) {
			// Wake up svelte
			board = board;

			// Notify the opponent
			if (onlinePlayer) {
				onlinePlayer.sendPlay(i, j);
			}
		}
	}

    function onLocalPass(event) {
        if (onlinePlayer && onlinePlayer.color == getColor(board.color)) {
            //console.warn('Local player played while it is not its turn');
            return;
        }

        board.pass();

        // Wake up svelte
        board = board;

        // Notify the opponent
        if (onlinePlayer) {
            onlinePlayer.sendPass();
        }
	}

    function onOnlinePlay(event) {
        if (onlinePlayer && onlinePlayer.color != getColor(board.color)) {
            onError('Online player played while it is not its turn');
            return;
        }

        switch (event.type) {
            case 'play':
                const i = event.row;
                const j = event.col;

                if (board.play(i, j)) {
                    // Wake up svelte
                    board = board;
                } else {
                    onError('Online player did an invalid action');
                }
                break;

            case 'pass':
                board.pass();
                board = board;
                notify('Player passed');
                break;
        }
    }

    function onError(message) {
        console.error(message);
        notify(message, 'error');
    }

    function onResize() {
        setTimeout(() => {
            board = board;
        }, 100);
    }

    onMount(() => {
        setTimeout(() => {
            board = board;
        }, 2000);
        return () => {
            if (onlinePlayer) {
                onlinePlayer.leave();
                onlinePlayer.destroy();
            }
        };
    });
</script>

<style>
	.main {
		position: absolute;
		top: 0;
		bottom: 0;
		width: 100%;
        height: 100%;
        background-color: #f0f0f0;
	}
    .game {
        position: absolute;
		top: 64px;
        bottom: 0;
		left: 0;
        right: 0;
		width: 100%;
        height: calc(100% - 64px);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #a08040;
    }
    .board {
        position: relative;
        width: 100%;
	}
    .pass {
        position: absolute;
        top: 0;
        right: 0;
        color: #101010;
        border: none;
        background-color: none;
        box-sizing: content-box;
        width: 48px;
        height: 48px;
        margin: 0;
        padding: 8px;
        font-size: 16px;
        line-height: 48px;
        text-align: center;
        cursor: pointer;
        user-select: none;
    }
    .hud {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .stone {
        display: inline-flex;
        position: relative;
        width: 64px;
        height: 64px;
    }
	@media (min-aspect-ratio: 1/1) {
		.game {
			top: 0;
        	left: 64px;
            width: calc(100% - 64px);
            height: 100%;
		}
		.pass {
			top: unset;
			right: unset;
			bottom: 0;
			left: 0;
		}
        .hud {
            right: unset;
            bottom: 0;
            flex-direction: column;
        }
	}
</style>

<svelte:window on:resize="{onResize}" />

<div class="main">
    <div class="game" bind:clientWidth="{clientWidth}" bind:clientHeight="{clientHeight}">
        <div class="board" style="width: {boardWidth}px;">
            <SVGBoard board="{board}"
                active="{!onlinePlayer || onlinePlayer && onlinePlayer.entered && onlinePlayer.color != getColor(board.color)}"
                on:play="{onLocalPlay}"
                on:pass="{onLocalPass}" />
            {#if onlinePlayer}
                <OnlineModal player="{onlinePlayer}" />
            {/if}
            {#if board.status.over}
                <GameOverModal captured="{board.captured}" />
            {/if}
        </div>
    </div>
    <div class="hud">
        <div class="stone">
            <DOMStone color="black"
                fade="{board.color != Board.Black || board.status.over}"
                highlight="{board.color == Board.Black && !board.status.over}"
                spinner="{!board.status.over && board.color == Board.Black && onlinePlayer && onlinePlayer.entered && onlinePlayer.color == 'black'}" />
        </div>
        <div class="stone">
            <DOMStone color="white"
                fade="{board.color != Board.White || board.status.over}"
                highlight="{board.color == Board.White && !board.status.over}"
                spinner="{!board.status.over && board.color == Board.White && onlinePlayer && onlinePlayer.entered && onlinePlayer.color == 'white'}" />
        </div>
    </div>
    <button class="pass" on:click="{onLocalPass}">Pass</button>
</div>