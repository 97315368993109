<script context="module">
    import Notification from './notification.svelte';

    let container;

    export function notify(message, type = '') {

        const options = {
            message: message,
            type: type
        };

        const el = new Notification({
            "target": container,
            "props": options,
            "intro": true
        });

        el.$on('close', () => {
            el.$destroy();
        });

        return el;
    }

    document.addEventListener('DOMContentLoaded', () => {
        container = document.createElement('div');
        container.classList.add('notify');
        document.body.appendChild(container);
    });
</script>

<style>
    :global(.notify) {
        position: fixed;
        z-index: 999;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;
        pointer-events: none;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: column;
    }
</style>