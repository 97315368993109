<script>
    import Modal from './ui/modal.svelte';

    export let captured = {
        black: 0,
        white: 0
    };
</script>

<style>
    .content {
        padding: 16px;
        background-color: #f0f0f0;
        width: 320px;
    }
	p {
		margin: 16px 8px;
        text-align: center;
	}
</style>

<Modal>
    <div class="content">
        <p>Game over</p>
        <p>Black: {captured.black}</p>
        <p>White: {captured.white}</p>
    </div>
</Modal>