<script>
    import { getContext } from 'svelte';

    export let active = false;
    export let disabled = false;
    export let value;

    let toggle = false;

    const context = getContext('tabs');

    if (context) {
        context.onChange((v, multiple) => {
            if (multiple) {
                active = v.includes(value);
            } else {
                active = (v == value);
            }
        });
        toggle = context.toggle;
    }

    function onClick() {
        if (context) {
            context.change(value);
        }
    }
</script>

<style>
    li {
        display: block;
        margin: 0;
        padding: 0;
        flex-grow: 1;
        flex-shrink: 0;
    }
    .tab {
        color: #404040;
        border-bottom: 2px solid #d0d0d0;
        padding: 8px 12px;
        text-align: center;
        cursor: pointer;
    }
    .tab.toggle {
        background-color: white;
        border-top: 1px solid #d0d0d0;
        border-bottom: 1px solid #d0d0d0;
    }
    li:first-child .tab.toggle {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-left: 1px solid #d0d0d0;
    }
    li:last-child .tab.toggle {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-right: 1px solid #d0d0d0;
    }
    .tab.active {
        color: #4080d0;
        border-color: #4080d0;
    }
    .tab.toggle.active {
        color: white;
        background-color: #4080d0;
        border-color: #4080d0;
    }
    .tab.disabled {
        color: #e0e0e0;
        cursor: auto;
        pointer-events: none;
    }
</style>

<li>
    <div class="tab"
        class:active="{active}"
        class:disabled="{disabled}"
        class:toggle="{toggle}"
        on:click="{onClick}">
            <slot />
    </div>
</li>