<script>
    import Modal from './ui/modal.svelte';
    import Button from './ui/button.svelte';

    export let player;
    let hostRoomInput;

    function onCopy() {
        hostRoomInput.select();
        hostRoomInput.setSelectionRange(0, 99999);
        document.execCommand('copy');
    }
</script>

<style>
    .content {
        padding: 16px;
        background-color: #f0f0f0;
        width: 320px;
    }
	.field {
		margin: 16px 8px;
	}
	.label {
        display: block;
        color: #808080;
		text-align: left;
        font-size: 14px;
	}
    .input {
        position: relative;
        width: 100%;
    }
    .input.room-id input {
        font-size: 14px;
        flex: 1;
    }
</style>

{#if player && !player.entered}
    <Modal>
        <div class="content">
            {#if player.host}
                <div class="field">
                    <label class="label">Room ID</label>
                    <div class="input room-id">
                        <input bind:this="{hostRoomInput}" type="text" value="{player.room ? player.room : 'Generating...'}" readonly>
                        <Button small disabled="{player.room == null}" on:click="{onCopy}">Copy</Button>
                    </div>
                </div>
            {:else}
                Connecting to room {player.room}...
            {/if}
        </div>
    </Modal>
{/if}