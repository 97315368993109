<script>
    import { createEventDispatcher, setContext } from 'svelte';

    export let multiple = false;
    export let toggle = false;
    export let value = multiple ? [] : undefined;

    const dispatch = createEventDispatcher();
    const listeners = [];

    const context = {
        change: v => {
            if (multiple) {
                if (value.includes(v)) {
                    value = value.filter(u => u !== v);
                } else {
                    value = [...value, v];
                }
            } else {
                value = v;
            }
            dispatch('change', value);
        },
        onChange: fn => {
            listeners.push(fn);
            fn(value, multiple);
        },
        toggle: toggle
    };

    $: listeners.forEach(fn => fn(value, multiple));

    setContext('tabs', context);
</script>

<style>
    .tabs {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
    }
    .tabs ul {
        list-style: none;
        margin: 0;
        padding: 0;
        align-items: center;
        display: flex;
        flex-grow: 1;
        flex-shrink: 0;
        justify-content: flex-start;
    }
</style>

<div class="tabs">
    <ul>
        <slot />
    </ul>
</div>