<script>
    export let color;
    export let highlight = false;
    export let fade = false;
    export let circle = false;
    export let cross = false;
    export let pulse = false;
    export let spinner = false;
</script>

<style>
    .black,
    .white {
        display: block;
        position: absolute;
        top: 4%;
        left: 4%;
        box-sizing: border-box;
        width: 92%;
        height: 92%;
        border-radius: 50%;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.33);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .black {
        background-color: black;
        background-image: radial-gradient(circle at top left, #c0c0c0 0%, black 50%);
    }
    .white {
        background-color: white;
        background-image: radial-gradient(circle at top left, white 50%, #808080 100%);
    }
    .pulse {
        animation: pulse 2s infinite;
    }
    .black.highlight {
        box-shadow: 0 0 4px 1px black;
    }
    .white.highlight {
        box-shadow: 0 0 4px 1px black;
    }
    .fade {
        opacity: 0.33;
    }
    .circle:after {
        content: '';
        display: block;
        box-sizing: border-box;
        width: 70%;
        height: 70%;
        border-radius: 50%;
    }
    .cross:after {
        content: '⨯';
        display: block;
        font-size: 24px;
    }
    .black.cross:after {
        color: white;
    }
    .white.cross:after {
        color: black;
    }
    .black.circle:after {
        border: 2px solid #c0c0c0;
    }
    .white.circle:after {
        border: 2px solid #404040;
    }
    .spinner:after {
        animation: spinnerContent 2s linear infinite;
        display: block;
        content: "⠋";
        font-size: 24px;
    }
    .black.spinner:after {
        color: white;
    }
    .white.spinner:after {
        color: black;
    }
    @keyframes pulse {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.33;
        }
        100% {
            opacity: 1;
        }
    }
    @keyframes spinnerContent {
         0% { content: "⠋"; }
        10% { content: "⠙"; }
        20% { content: "⠹"; }
        30% { content: "⠸"; }
        40% { content: "⠼"; }
        50% { content: "⠴"; }
        60% { content: "⠦"; }
        70% { content: "⠧"; }
        80% { content: "⠇"; }
        90% { content: "⠏"; }
    }
</style>

<div class="{color}"
    class:highlight="{highlight}"
    class:fade="{fade}"
    class:circle="{circle}"
    class:cross="{cross}"
    class:pulse="{pulse}"
    class:spinner="{spinner}"></div>