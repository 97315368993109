<script>
	import Menu from './menu.svelte';
	import Curtain from './ui/curtain.svelte';
	import Modal from './ui/modal.svelte';
	import Button from './ui/button.svelte';
	import Game from './game.svelte';
    import { onMount } from 'svelte';

	let game;
	let curtainOpen = false;
	let confirmStart = false;
	let gameOptions = null;
	let innerHeight = 320;

	function onStart(event) {
		if (!confirmStart) {
			gameOptions = event.detail;
		}
		if (!confirmStart && game.started) {
			confirmStart = true;
		} else {
			curtainOpen = false;
			confirmStart = false;
			game.start(gameOptions);
		}
	}

    onMount(() => {
        setTimeout(() => {
            innerHeight = window.innerHeight;
        }, 3000);
    });
</script>

<style>
	.main {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.curtain {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		padding-top: 64px;
		background-color: white;
		display: flex;
        justify-content: center;
		overflow-y: auto;
	}
	.modal {
		width: 320px;
		padding: 16px;
		background-color: white;
		box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.66);
	}
</style>

<svelte:window bind:innerHeight="{innerHeight}" />

<div class="main" style="height: {innerHeight}px;">
	<Game bind:this="{game}" />

	<Curtain bind:active="{curtainOpen}">
		<div class="curtain">
			<Menu on:start="{onStart}" />
		</div>
	</Curtain>

	<Modal active="{confirmStart}" on:close="{() => {confirmStart = false;}}">
		<div class="modal">
			<div>Stop current game?</div>
			<Button large on:click="{onStart}">OK</Button>
			<Button large>Cancel</Button>
		</div>
	</Modal>
</div>
