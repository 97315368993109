<script>
    export let disabled = false;
    export let large = false;
    export let small = false;
    export let primary = false;
    export let success = false;
</script>

<style>
    .button {
        display: inline-block;
        color: #404040;
        background-color: white;
        border: 1px solid #d0d0d0;
        border-radius: 4px;
        margin: 0;
        padding: 8px 12px;
        text-align: center;
        cursor: pointer;
        font-size: 16px;
    }
    .button[disabled] {
        color: #e0e0e0;
        cursor: auto;
        pointer-events: none;
    }
    .button.large {
        padding: 16px 32px;
        font-size: 18px;
    }
    .button.small {
        padding: 4px 8px;
        font-size: 14px;
    }
    .button.primary {
        color: white;
        background-color: #4080d0;
        border-color: #4080d0;
    }
    .button.success {
        color: white;
        background-color: #00e040;
        border-color: #00e040;
    }
</style>

<button class="button"
        class:large="{large}"
        class:small="{small}"
        class:primary="{primary}"
        class:success="{success}"
        disabled="{disabled}"
        on:click>
            <slot />
</button>