<script>
    export let active = false;
    export let closeable = true;
</script>

<style>
    .open,
    .close {
        position: absolute;
        top: 0;
        width: 64px;
        height: 64px;
        border: none;
        background-color: none;
        cursor: pointer;
        user-select: none;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 32px;
        color: #404040;
    }
    .open {
        left: 0;
    }
    .close {
        right: 0;
    }
    .menu {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        transform: translateX(-100%);
        transition: transform 0.2s linear;
        pointer-events: none;
    }
    .menu.active {
        transform: translateX(0);
        pointer-events: auto;
        box-shadow: 4px 0 8px rgba(0, 0, 0, 0.66);
    }
</style>

<div class="open" on:click="{() => {active = true;}}">☰</div>
<div class="menu" class:active="{active}">
    <slot />
    {#if closeable}
        <div class="close" on:click="{() => {active = false;}}">
            <div class="cross">⨯</div>
        </div>
    {/if}
</div>