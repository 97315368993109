<script>
    import { createEventDispatcher } from 'svelte';

    export let message = '';
    export let active = true;
    export let type = '';

    const dispatch = createEventDispatcher();

    function handleClick() {
        active = false;
        dispatch('close');
    }
</script>

<style>
    .notification {
        display: inline-flex;
        pointer-events: auto;
        max-width: 320px;
        opacity: 0.9;
        margin: 8px;
        padding: 16px;
        border-radius: 8px;
        cursor: pointer;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.66);
        color: white;
        background-color: #808080;
    }
    .notification.error {
        color: white;
        background-color: #ff0000;
    }
    .notification.warning {
        color: #404040;
        background-color: #ffc080;
    }
</style>

{#if active}
    <div class="notification {type}" on:click="{handleClick}">
        {@html message}
    </div>
{/if}