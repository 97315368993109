interface Cell {
    row: number,
    col: number,
    color?: number
}

interface Action {
    pass: boolean,
    row?: number,
    col?: number
}

interface Score {
    black: number,
    white: number
}

/**
 * Represents a Go board.
 */
class Board {

    static Empty = 0;
    static Black = 1;
    static White = -1;

    // Number of rows
    private _rows: number;

    // Number of columns
    private _cols: number;

    // An array of positions of all stones
    private _stones: Cell[];

    // A 2D array representing the current configuration
    private _matrix: number[][];

    // The last configuration
    private _lastMatrix: number[][];

    // The color of the current player
    private _color: number;

    // The history of actions
    private _history: Action[];

    // Information of the current status
    private _status: any;

    // Number of stones captures for each player
    private _captured: Score;

    /**
     * Constructor.
     */
    constructor(rows: number, cols?: number) {
        if (!cols) {
            cols = rows;
        }
        this._rows = rows;
        this._cols = cols;
        this._stones = [];
        this._matrix = Array.from(Array(rows), () => Array.from(Array(cols), () => Board.Empty));
        this._lastMatrix = [];
        this._color = Board.Black;
        this._history = [];
        this._status = {
            passed: false,
            suicide: false,
            atari: false,
            ko: false,
            over: false
        };
        this._captured = {
            black: 0,
            white: 0
        };
    }

    /**
     * Return the number of rows.
     */
    get rows(): number {
        return this._rows;
    }

    /**
     * Return the number of columns.
     */
    get cols(): number {
        return this._cols;
    }

    /**
     * Return the array of stone positions and color.
     */
    get stones(): Cell[] {
        return this._stones;
    }

    /**
     * Return the color of the current player.
     */
    get color(): number {
        return this._color;
    }

    /**
     * Return an array of the past actions.
     */
    get history(): Action[] {
        return this._history;
    }

    /**
     * Return the last action.
     */
    get last(): Action | null {
        return this.history.length > 0 ?
            this.history[this.history.length-1] : null;
    }

    /**
     * Return an object representing the current status.
     */
    get status(): any {
        return this._status;
    }

    /**
     * Return the number of stones captured for each player.
     */
    get captured(): Score {
        return this._captured;
    }

    /**
     * Return the type of cell at given coordinates.
     * @param i Row number.
     * @param j Column number.
     * @returns A value representing the type of cell.
     */
    get(i: number, j: number): number {
        return this._matrix[i][j];
    }

    /**
     * Put a stone at given coordinates.
     * @param i Row number.
     * @param j Column number.
     * @returns true if the move was valid, false otherwise.
     */
    play(i: number, j: number): boolean {
        // Check if the cell is empty and not out-of-bounds
        if (i < 0 || i >= this.rows ||
            j < 0 || j >= this.cols ||
            this._matrix[i][j] != Board.Empty) {
                return false;
        }

        // Save the matrix to restore it if needed
        const lastMatrix = cloneMatrix(this._matrix);

        // Apply the action
        this._matrix[i][j] = this.color;

        // Reset status
        this._status.suicide = false;
        this._status.atari = false;
        this._status.ko = false;

        // An array of the captured groups
        const captured: Cell[][] = [];

        // Save the atari status globally for later if the action is confirmed
        let atari = false;

        // Adjacent stones
        const adjacent = this.adjacent(i, j);

        // Get all the captured groups
        adjacent.forEach(p => {
            // For each adjacent stone that is not of the player's color...
            const c = this._matrix[p.row][p.col];
            if (c != Board.Empty && c != this.color &&
                // ...and not yet captured
                !captured.some(g => g.find((s: Cell) => sameCell(s, p)))) {
                    // Get the group
                    const group = this.group(p.row, p.col);
                    if (group) {
                        const liberties = this.liberties(group);
                        if (liberties.length == 0) {
                            captured.push(group);
                        } else if (liberties.length == 1) {
                            atari = true;
                        }
                    }
            }
        });

        // Player committed suicide if the position has no liberty after action
        if (captured.length == 0
            && this.liberties(this.group(i, j)).length == 0) {
                this._matrix[i][j] = Board.Empty;
                this._status.suicide = true;
                return false;
        }

        // Update the matrix by removing the captured stones
        captured.forEach(group => {
            group.forEach((p: Cell) => {
                this._matrix[p.row][p.col] = Board.Empty;
            });
        });

        // Check if we have a ko status by comparing with the n-2 matrix
        if (this._lastMatrix.length > 0 &&
            sameMatrix(this._matrix, this._lastMatrix)) {
                this._matrix = lastMatrix;
                this._status.ko = true;
                return false;
        }

        // Update atari status
        if (atari) {
            this._status.atari = true;
        }

        // The action is now confirmed
        this._stones = [];
        for (let i_ = 0; i_ < this.rows; i_++) {
            for (let j_ = 0; j_ < this.cols; j_++) {
                const c = this._matrix[i_][j_];
                if (c != Board.Empty) {
                    this._stones.push({
                        row: i_,
                        col: j_,
                        color: c
                    });
                }
            }
        }
        this._lastMatrix = lastMatrix;
        this._history.push({pass: false, row: i, col: j});
        this._status.passed = false;

        // Add the number of captured stones to the counter
        const numCaptured = captured.reduce((p, group) => p + group.length, 0);
        if (numCaptured > 0) {
            if (this.color == Board.Black) {
                this._captured.black += numCaptured;
            } else if (this.color == Board.White) {
                this._captured.white += numCaptured;
            }
        }

        // Change player
        this._changeColor();
        return true;
    }

    /**
     * Don't put a stone and switch to the other player's turn.
     */
    pass() {
        if (this._status.passed) {
            this._status.over = true;
        }
        this._lastMatrix = cloneMatrix(this._matrix);
        this._history.push({pass: true});
        this._status.passed = true;
        this._changeColor();
    }

    /**
     * Return an array of adjacent positions.
     * @param i Row number.
     * @param j Column number.
     * @returns An array of positions.
     */
    adjacent(i: number, j: number): Cell[] {
        const adj = [];
        if (i > 0) {
            adj.push({ row: i-1, col: j });
        }
        if (i < this.rows - 1) {
            adj.push({ row: i+1, col: j });
        }
        if (j > 0) {
            adj.push({ row: i, col: j-1 });
        }
        if (j < this.cols - 1) {
            adj.push({ row: i, col: j+1 });
        }
        return adj;
    }

    /**
     * Find all adjacent stones of the same color.
     * @param i Row number of the stone of interest.
     * @param j Column number of the stone of interest.
     * @returns An array of positions.
     */
    group(i: number, j: number): Cell[] {
        const color = this._matrix[i][j];
        if (color == Board.Empty) {
            return [];
        }

        const queue: Cell[] = [{row: i, col: j}];
        const visited: Cell[] = [];

        while (queue.length > 0) {
            const stone = queue.pop();
            if (stone && !visited.find(p => sameCell(p, stone))) {
                // Add adjacent stones with same color to the queue
                const adjacent = this.adjacent(stone.row, stone.col);
                adjacent.forEach(p => {
                    const c = this._matrix[p.row][p.col];
                    if (c == color) {
                        queue.push({...p});
                    }
                });
                visited.push(stone);
            }
        }
        return visited;
    }

    /**
     * Return an array of liberties of a group of stones.
     * @param group An array of positions.
     * @returns An array of positions.
     */
    liberties(group: Cell[]): Cell[] {
        const cells: Cell[] = [];

        group.forEach(stone => {
            const adjacent = this.adjacent(stone.row, stone.col);
            adjacent.forEach(p => {
                const c = this._matrix[p.row][p.col];
                if (c == Board.Empty && !cells.find(q => sameCell(q, p))) {
                    cells.push({...p});
                }
            });
        });
        return cells;
    }

    /**
     * Change the current player.
     */
    private _changeColor() {
        this._color = (this._color == Board.Black) ? Board.White : Board.Black;
    }
}

/**
 * Deep clone a matrix.
 */
function cloneMatrix(m: number[][]): number[][] {
    return m.map(row => row.slice(0))
}

/**
 * Compare two positions.
 */
function sameCell(p1: Cell, p2: Cell): boolean {
    return p1.row == p2.row && p1.col == p2.col;
}

/**
 * Compare two matrices.
 */
function sameMatrix(m1: number[][], m2: number[][]): boolean {
    if (m1.length != m2.length) {
        return false;
    }
    for (let i = 0; i < m1.length; i++) {
        if (m1[i].length != m2[i].length) {
            return false;
        }
        for (let j = 0; j < m1[i].length; j++) {
            if (m1[i][j] != m2[i][j]) {
                return false;
            }
        }
    }
    return true;
}

export default Board;