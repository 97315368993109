<script>
    export let color;
    export let x;
    export let y;
    export let radius;
    export let highlight = false;
    export let fade = false;
    export let mark = false;
</script>

<style>
    .stone {
        stroke: none;
        filter: url(#stone-shadow);
    }
    .stone.black {
        fill: black;
        fill: url(#black-gradient);
    }
    .stone.white {
        fill: white;
        fill: url(#white-gradient);
    }
    .fade {
        opacity: 0.66;
    }
    .highlight {
        stroke-width: 3px;
        fill: none;
    }
    .highlight.black {
        stroke: white;
    }
    .highlight.white {
        stroke: black;
    }
    .mark.black {
        fill: #c0c0c0;
    }
    .mark.white {
        fill: #808080;
    }
</style>

<circle class="stone"
    class:black="{color == 'black'}"
    class:white="{color == 'white'}"
    class:fade="{fade}"
    cx="{x}" cy="{y}" r="{radius}" />

{#if highlight}
    <circle class="highlight"
        class:black="{color == 'black'}"
        class:white="{color == 'white'}"
        cx="{x}" cy="{y}" r="{radius}" />
{/if}

{#if mark}
    <circle class="mark"
        class:black="{color == 'black'}"
        class:white="{color == 'white'}"
        cx="{x}" cy="{y}" r="{0.33 * radius}" />
{/if}