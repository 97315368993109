import Peer from 'peerjs';

/**
 * Represents a player who is online.
 */
class OnlinePlayer {

    private _peer: Peer;
    private _connection?: any;
    private _room: string | null = null;
    private _entered: boolean = false;
    private _options: any = null;
    private _color: string | null = null;

    get room(): string | null {
        return this._room;
    }

    get host(): boolean {
        return this._options.mode == 'host';
    }

    get color(): string | null {
        return this._color;
    }

    get open(): boolean {
        return this._connection && this._connection.open;
    }

    get entered(): boolean {
        return this._entered;
    }

    constructor(options: any) {
        this._options = {
            onOpen: () => {},
            onClose: () => {},
            onEnter: () => {},
            onLeave: () => {},
            onPlay: () => {},
            onPass: () => {},
            onError: () => {},
            ...options
        };

        this._entered = false;

        if (this.host) {
            if (['black', 'white'].includes(this._options.color)) {
                this._color = (this._options.color == 'black') ? 'white' : 'black';
            } else {
                const k = Math.floor(Math.random() * 2);
                this._color = (k == 0) ? 'black' : 'white';
            }
        } else {
            this._room = this._options.room;
        }

        const peer = new Peer();

        peer.on('open', (id: string) => this.onPeerOpen(id));
        peer.on('disconnected', () => this.onPeerDisconnected());
        peer.on('close', () => this.onPeerClose());
        peer.on('error', (error: any) => this.onPeerError(error));

        this._peer = peer;
    }

    connect(): boolean {
        if (!this.room) {
            return false;
        }

        const conn = this._peer.connect(this.room);

        conn.on('open', () => this.onConnectionOpen());
        conn.on('data', (data: any) => this.onConnectionData(data));
        conn.on('close', () => this.onConnectionClose());

        this._connection = conn;
        return true;
    }

    listen() {
        this._peer.on('connection', (conn: any) => this.onPeerConnection(conn));
    }

    close() {
        if (!this._connection) {
            return;
        }
        this._connection.close();
    }

    destroy() {
        this._peer.destroy();
    }

    sendPlay(i: number, j: number): boolean {
        return this.send({
            type: 'play',
            row: i,
            col: j
        });
    }

    sendPass(): boolean {
        return this.send({
            type: 'pass'
        });
    }

    sendLeave(): boolean {
        return this.send({
            type: 'leave'
        });
    }

    private send(data: any): boolean {
        if (!this._connection || !this._connection.open || !this._entered) {
            return false;
        }

        this._connection.send(data);
        return true;
    }

    private onPeerOpen(id: string) {
        console.log('Peer open:', id);
        if (this.host) {
            this._room = id;
            this._options.onOpen(id);
        }
    }

    private onPeerConnection(conn: any) {
        console.log('Peer connection:', conn);

        if (this._connection) {
            console.warn('Peer connection rejected');
            return;
        }

        conn.on('open', () => this.onConnectionOpen());
        conn.on('data', (data: any) => this.onConnectionData(data));
        conn.on('close', () => this.onConnectionClose());

        this._connection = conn;
    }

    private onPeerDisconnected() {
        console.log('Peer disconnected');
    }

    private onPeerClose() {
        console.log('Peer close');
    }

    private onPeerError(error: any) {
        console.error('Peer error:', error);
        this._options.onError(error);
    }

    private onConnectionOpen() {
        console.log('Connection open');

        if (this.host) {
            this._options.onOpen(this.room);
            this._connection.send({
                type: 'enter',
                size: this._options.size,
                color: this.color
            });
        }
    }

    private onConnectionData(data: any) {
        console.log('Connection data:', data);
        if (!data || !data.type) {
            return;
        }

        switch (data.type) {
            case 'enter':
                if (!this.host) {
                    this._color = (data.color == 'black') ? 'white' : 'black';
                    this._connection.send({
                        type: 'enter'
                    });
                }
                this._entered = true;
                console.log('Online player entered');
                this._options.onEnter(data);
                break;

            case 'leave':
                this._entered = false;
                console.log('Online player left (left)');
                this._options.onLeave(data);
                this._connection.close();
                break;

            case 'play':
                this._options.onPlay(data);
                break;

            case 'pass':
                this._options.onPass(data);
                break;
        }
    }

    private onConnectionClose() {
        console.log('Connection close');
        this._entered = false;
        console.log('Online player left (closed)');
        this._options.onLeave();
        this._options.onClose();
    }
}

export default OnlinePlayer;