<script>
    import { createEventDispatcher, onMount } from 'svelte';
    import Stone from './svgstone.svelte';
    import Board from './board.ts';

    export let board;
    export let active = true;
    export let disabled = false;

    const stoneRadius = 0.95;

    let container;
    let mounted = false;
    let highlightStones = [];
    let highlightCells = [];

    const dispatch = createEventDispatcher();

    // Unselect cells when the board has changed (watches board.last)
    $: if (board) {
        board.last;
        highlightStones = [];
        highlightCells = [];
    }

    function onCellClick(event) {
        const i = parseInt(event.target.dataset.row);
        const j = parseInt(event.target.dataset.col);

        if (board.get(i, j) == Board.Empty) {
            // Attempt to put a stone on (i, j)
            dispatch('play', {row: i, col: j});
        } else if (highlightStones.find(p => sameStone(p, {row: i, col: j}))) {
            // Re-click on the same stone: unselect
            highlightStones = [];
            highlightCells = [];
        } else {
            // Select the group of the clicked stone
            highlightStones = board.group(i, j);
            highlightCells = board.liberties(highlightStones);
        }
    }

    function x(j) {
        return ( j + 0.5 ) * container.clientWidth / board.cols;
    }

    function y(i) {
        return ( i + 0.5 ) * container.clientHeight / board.rows;
    }

    function sameStone(p1, p2) {
        return p1.row == p2.row && p1.col == p2.col;
    }

    function getColor(c) {
        switch (c) {
            case Board.Black:
                return 'black';
            case Board.White:
                return 'white';
        }
        return '';
    }

    function isStar(i, j) {
        const m = board.rows;
        const n = board.cols;

        if (m == 9 && n == 9) {
            return (i + 2) % 4 == 0 && (j + 2) % 4 == 0 || i == 4 && j == 4;
        } else if (m == 13 && n == 13) {
            return (i + 3) % 6 == 0 && (j + 3) % 6 == 0 || i == 6 && j == 6;
        } else if (m == 19 && n == 19) {
            return (i + 3) % 6 == 0 && (j + 3) % 6 == 0;
        }
        return false;
    }

    function onResize() {
        setTimeout(() => {
            board = board;
        }, 10);
    }

    onMount(() => {
        // Wait for the container to be rendered
        setTimeout(() => {
            mounted = true;
        }, 10);
    });
</script>

<svelte:window on:resize="{onResize}" />

<style>
    .container {
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 100%;
        overflow: hidden;
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.66);
    }
    .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.33);
    }
    .board {
        background-color: #e0b060;
        background-image: url('board.png');
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .lines .line {
        stroke: #101010;
        stroke-width: 1px;
    }
    .lines .star {
        stroke: none;
        fill: #101010;
        pointer-events: none;
    }
    .cells {
        stroke: none;
        fill: none;
    }
    .cell {
        cursor: pointer;
        pointer-events: all;
    }
    .cell.empty {
        cursor: auto;
        pointer-events: none;
    }
    .active .cell.empty {
        cursor: pointer;
        pointer-events: all;
    }
    .disabled .cell {
        cursor: auto;
        pointer-events: none;
    }
    .cell .ghost {
        pointer-events: none;
        display: none;
        opacity: 0.5;
    }
    .active .cell:hover .ghost {
        display: inline;
    }
    .disabled .cell:hover .ghost {
        display: none;
    }
    .mark {
        pointer-events: none;
        stroke: #101010;
        stroke-width: 3px;
        fill: #e0b060;
    }
    .stones .stone {
        pointer-events: none;
    }
</style>

<div class="container">
    <svg bind:this="{container}" class="board" xmlns="http://www.w3.org/2000/svg"
        class:black="{board && board.color == Board.Black}"
        class:white="{board && board.color == Board.White}"
        class:active="{active}"
        class:disabled="{disabled}">
            {#if board && mounted}
                <defs>
                    <radialGradient id="black-gradient" cx="0%" cy="0%" r="150%">
                        <stop offset="0%"  stop-color="#c0c0c0" />
                        <stop offset="50%"  stop-color="black" />
                        <stop offset="100%" stop-color="black" />
                    </radialGradient>
                    <radialGradient id="white-gradient" cx="0%" cy="0%" r="150%">
                        <stop offset="0%"  stop-color="white" />
                        <stop offset="50%"  stop-color="white" />
                        <stop offset="100%" stop-color="#808080" />
                    </radialGradient>
                    <filter id="stone-shadow">
                        <feGaussianBlur in="SourceAlpha" stdDeviation="2"/> <!-- blur -->
                        <feOffset dx="1" dy="1" result="offsetblur"/> <!-- offset -->
                        <feComponentTransfer>
                            <feFuncA type="linear" slope="0.66"/> <!-- opacity -->
                        </feComponentTransfer>
                        <feMerge>
                            <feMergeNode/>
                            <feMergeNode in="SourceGraphic"/>
                        </feMerge>
                    </filter>
                </defs>
                <g class="lines">
                    {#each [...Array(board.rows).keys()] as i}
                        <line class="horizontal line"
                            x1="{x(0*i)}" y1="{y(i)}"
                            x2="{x(board.cols - 1)}" y2="{y(i)}" />
                    {/each}
                    {#each [...Array(board.cols).keys()] as j}
                        <line class="vertical line"
                            x1="{x(j)}" y1="{y(0*j)}"
                            x2="{x(j)}" y2="{y(board.rows - 1)}" />
                    {/each}
                    {#each [...Array(board.rows).keys()] as i}
                        {#each [...Array(board.cols).keys()] as j}
                            {#if isStar(i, j)}
                                <circle class="star" cx="{x(j)}" cy="{y(i)}" r="{Math.max(2, 0.1 * (x(1) - x(0*i)))}" />
                            {/if}
                        {/each}
                    {/each}
                </g>
                <g class="highlight">
                    {#each highlightCells as cell}
                        <circle class="mark"
                            cx="{x(cell.col)}"
                            cy="{y(cell.row)}"
                            r="{0.25 * (x(1) - x(0*cell.col))}" />
                    {/each}
                </g>
                <g class="cells">
                    {#each [...Array(board.rows).keys()] as i}
                        {#each [...Array(board.cols).keys()] as j}
                            <g class="cell" class:empty="{board.get(i, j) == Board.Empty}">
                                {#if board.get(i, j) == Board.Empty}
                                    <g class="ghost">
                                        <Stone color="{getColor(board.color)}"
                                            x="{x(j)}" y="{y(i)}"
                                            radius="{stoneRadius * (x(0.5) - x(0*j))}" />
                                    </g>
                                {/if}
                                <rect x="{x(j - 0.5)}" y="{y(i - 0.5)}"
                                    width="{x(1) - x(0*j)}"
                                    height="{y(1) - y(0*j)}"
                                    data-row="{i}" data-col="{j}"
                                    on:click="{onCellClick}" />
                            </g>
                        {/each}
                    {/each}
                </g>
                <g class="stones">
                    {#each board.stones as stone}
                        <g class="stone">
                            <Stone color="{getColor(stone.color)}"
                                mark="{board.last && sameStone(board.last, stone)}"
                                highlight="{highlightStones.find(p => sameStone(p, stone))}"
                                fade="{highlightStones.length > 0 && !highlightStones.find(p => sameStone(p, stone))}"
                                x="{x(stone.col)}" y="{y(stone.row)}"
                                radius="{stoneRadius * (x(0.5) - x(0*stone.col))}" />
                        </g>
                    {/each}
                </g>
            {/if}
    </svg>
    {#if disabled}
        <div class="overlay"></div>
    {/if}
</div>