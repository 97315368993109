<script>
    import { createEventDispatcher } from 'svelte';

    export let active = true;
    export let closeable = false;

    const dispatch = createEventDispatcher();
</script>

<style>
    .modal {
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        justify-content: center;
        align-items: center;
		background-color: rgba(0, 0, 0, 0.66);
    }
    .modal.active {
        display: flex;
    }
    .close {
        position: absolute;
        top: 0;
        right: 0;
        width: 64px;
        height: 64px;
        cursor: pointer;
        user-select: none;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 32px;
        color: white;
    }
</style>

<div class="modal" class:active="{active}" on:click="{() => dispatch('close')}">
    <div class="content" on:click|stopPropagation="{() => false}">
        <slot />
    </div>
    {#if closeable}
        <div class="close" on:click="{() => dispatch('close')}">
            <div class="cross">⨯</div>
        </div>
    {/if}
</div>