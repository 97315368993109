<script>
	import Button from './ui/button.svelte';
	import Tabs from './ui/tabs.svelte';
	import Tab from './ui/tab.svelte';
    import { createEventDispatcher } from 'svelte';

    export let mode = 'local';
    export let size = 9;
    export let color = 'black';
    export let joinRoom = '';

    const dispatch = createEventDispatcher();

    function onStart() {

        switch (mode) {

            case 'local':
                dispatch('start', {
                    mode: mode,
                    size: size
                });
                break;

            case 'join':
                dispatch('start', {
                    mode: mode,
                    room: joinRoom
                });
                break;

            case 'host':
                dispatch('start', {
                    mode: mode,
                    size: size,
                    color: color
                });
                break;
        }
    }
</script>

<style>
	.menu {
		width: 320px;
	}
	.field {
		margin: 16px 8px;
	}
	.label {
        display: block;
        color: #808080;
		text-align: left;
        font-size: 14px;
	}
    .input {
        position: relative;
        width: 100%;
    }
    .input.room-id input {
        font-size: 14px;
        flex: 1;
    }
	.header {
		margin-bottom: 24px;
	}
	.footer {
		margin: 24px auto;
        text-align: center;
	}
</style>


<div class="menu">
    <div class="header">
        <Tabs bind:value="{mode}">
            <Tab value="local">Local</Tab>
            <Tab value="join">Join</Tab>
            <Tab value="host">Host</Tab>
        </Tabs>
    </div>
    {#if ['local', 'host'].includes(mode)}
        <div class="field">
            <label class="label">Size</label>
            <div class="input">
                <Tabs toggle bind:value="{size}">
                    <Tab value="{9}">9x9</Tab>
                    <Tab value="{13}">13x13</Tab>
                    <Tab value="{19}">19x19</Tab>
                </Tabs>
            </div>
        </div>
    {/if}
    {#if mode == 'host'}
        <div class="field">
            <label class="label">Color</label>
            <div class="input">
                <Tabs toggle bind:value="{color}">
                    <Tab value="black">Black</Tab>
                    <Tab value="white">White</Tab>
                    <Tab value="random">Random</Tab>
                </Tabs>
            </div>
        </div>
    {/if}
    {#if mode == 'join'}
        <div class="field">
            <label class="label">Room ID</label>
            <div class="input room-id">
                <input type="text" bind:value="{joinRoom}">
            </div>
        </div>
    {/if}
    <div class="footer">
        <Button large success on:click="{onStart}">Start</Button>
    </div>
</div>